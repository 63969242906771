/* You can add global styles to this file, and also import other style files */


/* Importing Bootstrap SCSS file. */

@import '~bootstrap/scss/bootstrap';
@import '~ngx-toastr/toastr-bs4-alert';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
$fa-font-path: "~../node_modules/@fortawesome/fontawesome-free-webfonts/webfonts";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;900&display=swap');
html,
body {
    height: 100%;
    margin: 0;
    font-family: 'Poppins', sans-serif!important;
}

.box-body {
    height: calc(100vh - 64px);
    overflow: auto;
    padding: 15px;
    background: white;
}

$gradientPrimary:linear-gradient(45deg,
#6b3fd9,
#716ee5);
:root {
    --blue: #00b; // or any global you wish to share with components
    --gradientPrimary: linear-gradient(45deg, #6b3fd9, #716ee5);
}

h4 {
    color: #4d47f6d9;
    font-weight: 600 !important;
    text-align: center;
    padding-top: 10px;
}

.btn-export {
    font-size: 13px;
    background-color: green;
    color: white;
    padding: 2px 5px;
    align-items: center;
}

.btn-create {
    padding: 8px 20px;
    font-weight: 500;
    background: linear-gradient(45deg, #6b3fd9, #716ee5);
    border: none;
    border-radius: 8px;
    color: white;
    margin: 0px 8px;
}

.btn-create:hover {
    background: linear-gradient(46deg, #716ee5, #6b3fd9);
}

.btn-cancel {
    padding: 8px 20px;
    font-weight: 500;
    border-radius: 8px;
    color: black;
    margin: 0px 8px;
    border: 1px solid #d5d5d5;
}

.error-text {
    color: red;
    font-size: 10px;
}


/* width */

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}


/* Track */

 ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 1px;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: blue;
    border-radius: 3px;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: blue;
}

fieldset {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    background: linear-gradient(180deg, white, #f3f3f3);
}

legend {
    font-weight: bold;
    padding: 0 10px;
    color: #7063e2;
}

ng2-smart-table {
    height: calc(100vh - 200px);
    overflow-y: scroll;
}

ng2-smart-action-custom-custom {
    padding-top: 0.8em;
    background-color: #007bff;
}

ng2-smart-table nav.ng2-smart-pagination-nav {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1.25rem;
    background-color: white;
    align-items: center;
}

ng2-smart-table nav.ng2-smart-pagination-nav .pagination {
    font-family: akkurat;
    font-size: 0.875rem;
    line-height: 1rem;
    border: #edf1f7 solid 1px;
    border-radius: 0.17rem;
}

ng2-smart-table nav.ng2-smart-pagination-nav .ng2-smart-pagination {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
}

ng2-smart-table nav.ng2-smart-pagination-nav .pagination li:not(:last-child) {
    border-right: 1px solid #edf1f7;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    height: 40px;
    cursor: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-color: #dee2e6;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

ng2-smart-table a:hover {
    text-decoration: none;
}

ng2-smart-table table tr th,
ng2-smart-table table tr td {
    position: relative;
    padding: 0.875rem 1.25rem;
    border: 1px solid #edf1f7;
    vertical-align: middle;
}

ng2-smart-table table tr.ng2-smart-titles th {
    padding-right: 1.75rem;
}

ng2-smart-table th.ng2-smart-actions-title-add a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem !important;
    padding: 0.375rem 0.5rem;
    border-color: #3366ff;
    background-color: #3366ff;
    color: #ffffff;
    border-radius: 0.375rem;
}

ng2-smart-table th.ng2-smart-actions-title-add a:hover {
    text-decoration: none;
    background-color: #6a90ff;
}

ng2-smart-table .ng2-smart-actions a.ng2-smart-action:not(:last-child) {
    border-right: 1px solid #edf1f7;
}

ng2-smart-table .ng2-smart-actions a.ng2-smart-action {
    font-size: 1rem !important;
    color: #000000;
    padding: 6px;
    text-align: center;
}

table {
    background-color: white;
}

.right-end {
    display: flex;
    justify-content: flex-end;
}

.btn-back {
    border: none;
    border-radius: 4px;
    color: black;
    padding: 10px;
    background-color: #ffffff;
    margin: 3px;
    cursor: pointer;
}

.btn-back:hover {
    background-color: #f8f9fa;
}

.actions {
    background: #0000002e;
    padding: 5px;
    display: flex;
    justify-content: flex-end;
}

.btn-export {
    border: none;
    text-align: center;
    padding: 4px 15px;
    background: #1f0286;
}

.mat-form-field-type-mat-date-range-input .mat-form-field-infix {
    display: flex!important;
    justify-content: space-between!important;
}

.my-form-field .mat-form-field-wrapper {
    margin-bottom: -1.25em!important;
}

.mat-list-base {
    padding-top: 8px;
    display: block;
    -webkit-tap-highlight-color: transparent;
    height: calc(100vh - 66px);
    overflow: auto;
}